<template>
  <material-card
    outlined
    class="fill-height"
  >
    <core-callout
      :title="$t('views.charts.tableDataPoint.title')"
      :subtitle="$t('views.charts.tableList.description')"
    >
      <slot />
    </core-callout>
    <v-data-table
      dense
      :headers="iriHeaders"
      :items="items"
      item-key="startStation"
      fixed-header
      style="overflow: auto"
      height="100%"
      hide-default-footer
      disable-pagination
      @mouseover:row="(event, { item }) => selectSection(item)"
      @click:row="(event, { item }) => selectSection(item)"
      @mouseleave:row="(event, { item }) => unselectSection(item)"
    >
      <template #[`item.startStation`]="{ item }">
        <v-icon :color="riskColor(item.RUni)"> $fas fa-map-marker-alt </v-icon>
        {{ item.startStation }}
      </template>
      <template #[`item.endStation`]="{ item }">
        {{ item.endStation }}
      </template>
      <template #[`item.iri`]="{ item }">
        {{ item.iri ? item.iri.toFixed(2) : '-' }}
      </template>
      <template #[`item.imagesCount`]="{ item }">
        <span
          :class="item.imagesCount === 0 ? 'text-danger' : 'text-success'"
          >{{ item.imagesCount }}</span
        >
      </template>
    </v-data-table>
  </material-card>
</template>

<script>
import inventoryDownloadMixin from '@/mixins/inventoryDownloadMixin'
import { IRIGranularities, risk as riskEnum } from '@/utils/enum'

export default {
  name: 'InventoryTable',

  mixins: [inventoryDownloadMixin],

  props: {
    segment: {
      type: Object,
      required: true,
    },
    orderedImages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      iriHeaders: [
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
          align: 'left',
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('enums.headers.imagesCount'),
          value: 'imagesCount',
          align: 'center',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    items() {
      const imagesGroupedByStation = this.groupImagesByEndStation(
        this.orderedImages,
        IRIGranularities.TEN_METERS,
      )

      return this.segment.iriSections10m.map((section) => {
        const imagesCount =
          imagesGroupedByStation[section.endStationInt]?.length || 0
        return {
          ...section,
          imagesCount,
        }
      })
    },
  },

  methods: {
    riskColor(risk) {
      switch (risk) {
        case riskEnum.HR:
          return 'error'
        case riskEnum.AR:
          return 'warning'
        case riskEnum.LR:
          return 'success'
        default:
          return '#999999'
      }
    },

    selectSection(item) {
      this.$emit('section-selected', item)
    },

    unselectSection() {
      this.$emit('section-selected', undefined)
    },
  },
}
</script>
