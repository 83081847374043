<template>
  <v-card
    style="overflow: hidden"
    outlined
  >
    <v-img
      :src="imageObjects[index]?.src"
      class="grey darken-4 flex-grow-1"
      contain
      max-height="95%"
    />
    <div class="d-flex justify-space-between">
      <div>
        <v-btn
          icon
          depressed
          :disabled="previousDisabled"
          class="inline"
          @click="previous"
        >
          <v-icon>$fas fa-caret-left</v-icon>
        </v-btn>
        <span class="mx-4"> {{ index + 1 }} / {{ images.length || 1 }} </span>
        <v-btn
          icon
          depressed
          :disabled="nextDisabled"
          class="inline"
          @click="next"
        >
          <v-icon>$fas fa-caret-right</v-icon>
        </v-btn>
        <span class="mx-4">
          Section: {{ selected?.startStation }} -
          {{ selected?.endStation }}
        </span>
      </div>
      <div v-if="isSuperAdmin">
        <v-btn
          icon
          depressed
          :disabled="!selected"
          color="danger"
          @click="dialog = true"
        >
          <v-icon>$fas fa-trash</v-icon>
        </v-btn>
      </div>
    </div>
    <v-dialog
      v-model="dialog"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{ $t('views.image-upload.delete') }}
        </v-card-title>

        <v-divider />

        <v-card-text class="text-subtitle-1">
          {{ $t('views.image-upload.delete-message', { count: 1 }) }}
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="blue darken-1"
            text
            :disabled="disabled"
            @click="dialog = false"
            >{{ $t('views.image-upload.cancel') }}
          </v-btn>
          <v-spacer />
          <v-btn
            color="danger"
            class="white--text"
            :disabled="disabled"
            @click="deleteSelected"
          >
            {{ $t('views.inventory.delete') }}
            <v-icon
              dark
              right
              size="20"
            >
              $far fa-trash-can
            </v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import { mapActions, mapMutations, mapGetters, mapState } from 'vuex'
import { roles } from '@/utils/enum'

export default {
  name: 'InventoryViewer',

  data() {
    return {
      index: 0,
      dialog: false,
      disabled: false,
    }
  },

  computed: {
    ...mapGetters(['userHasRole']),
    ...mapState('inventory', ['images']),

    isSuperAdmin() {
      return this.userHasRole(roles.SUPER_ADMIN)
    },

    nextDisabled() {
      return this.images.length === 0 || this.index >= this.images.length - 1
    },
    previousDisabled() {
      return this.images.length === 0 || this.index <= 0
    },

    imageObjects() {
      return this.images.map((image) => {
        const img = new Image()
        img.src = `${process.env.VUE_APP_BASE_URL}/images/${image._id}`
        return img
      })
    },

    selected() {
      if (!this.images || this.images.length === 0) {
        return null
      }
      return this.images[this.index]
    },
  },

  watch: {
    index: {
      immediate: true,
      handler() {
        this.setCurrentImage(this.images[this.index])
      },
    },
  },

  mounted() {
    window.addEventListener('keydown', this.handleKeyDown)
  },

  beforeDestroy() {
    window.removeEventListener('keydown', this.handleKeyDown)
  },

  methods: {
    ...mapActions('images', ['deleteImage']),
    ...mapMutations('images', ['setCurrentImage']),
    ...mapMutations('inventory', ['setImages']),

    next() {
      if (this.nextDisabled) return
      this.index++
    },

    previous() {
      if (this.previousDisabled) return
      this.index--
    },

    handleKeyDown(event) {
      if (event.key === 'ArrowLeft') {
        this.previous()
      } else if (event.key === 'ArrowRight') {
        this.next()
      }
    },

    async deleteSelected() {
      this.disabled = true
      await this.deleteImage(this.selected._id)

      const newImages = this.images.filter(
        (item) => item._id !== this.selected._id,
      )

      // No more images left
      if (newImages.length === 0) {
        this.$emit('close')
        this.dialog = false
        return
      }

      // Image was last in the list
      if (this.index === this.images.length - 1) {
        this.index--
      }

      this.setImages(newImages)

      this.dialog = false
      this.disabled = false
    },
  },
}
</script>
