import { arrayToCsv } from '@/utils/csv'
import { IRIGranularities } from '@/utils/enum'

export default {
  methods: {
    triggerDownload(csvContent, filename) {
      const link = document.createElement('a')
      link.setAttribute('href', csvContent)
      link.setAttribute('download', filename)

      document.body.appendChild(link)
      link.click()
      link.remove()
    },

    generateCSV() {
      const body = this.generateCSVBody()
      const headers = this.generateCSVHeaders()
      this.maxAssetsCount = 0

      return (
        'data:text/csv;charset=utf-8,' + encodeURI(arrayToCsv(headers, body))
      )
    },

    groupImagesByEndStation(images, granularity) {
      let endStations

      if (granularity === IRIGranularities.TEN_METERS) {
        endStations = this.segment.iriSections10m.map(
          (section) => section.endStationInt,
        )
      } else {
        endStations = this.segment.iriSections.map(
          (section) => section.endStationInt,
        )
      }

      const result = {}
      let index = 0
      let currentGroup = []

      for (const endStation of endStations) {
        result[endStation] = []
      }

      for (const image of images) {
        while (
          index < endStations.length &&
          image.endStationInt > endStations[index]
        ) {
          result[endStations[index]] = currentGroup
          currentGroup = []
          index++
        }

        if (index < endStations.length) {
          currentGroup.push(image)
        }
      }

      if (index < endStations.length) {
        result[endStations[index]] = currentGroup
      }

      return result
    },
  },
}
