import Vue from 'vue'

import resetState from '@/utils/resetState'
import { initialInventoryState } from './state'

export default {
  removeDefect(state, imageId) {
    delete state.defects[imageId]
  },

  updateDefects(state, { imageId, defectId, data }) {
    Vue.set(state.defects, imageId, { id: defectId, defects: data })
  },

  removeAsset(state, imageId) {
    delete state.assets[imageId]
  },

  updateAssets(state, { imageId, assetId, data }) {
    Vue.set(state.assets, imageId, { id: assetId, assets: data })
  },

  reset(state) {
    Vue.set(state, 'defects', {})
    Vue.set(state, 'assets', {})
  },

  addToSelectedAssets(state, asset) {
    state.selectedAssets.push(asset)
  },

  setSelectedAssets(state, assets) {
    state.selectedAssets = assets
  },

  setGroupToAssetsMap(state, map) {
    state.groupToAssetsMap = map
  },

  setImages(state, images) {
    state.images = images
  },

  clearState(state) {
    resetState(state, initialInventoryState)
  },
}
