import { round, clamp, sumBy } from 'lodash'

import { defectTypes } from './enum'
import { defaultWidths, defaultLengths } from './constants'

const severityMap = {
  1: 'low',
  2: 'low',
  3: 'medium',
  4: 'medium',
  5: 'high',
}

const correctedDeductValueEquations = {
  1: {
    multiplier: 1,
    exponent: 1,
  },
  2: {
    multiplier: 0.8688,
    exponent: 0.954,
  },
  3: {
    multiplier: 0.5722,
    exponent: 1.0167,
  },
  4: {
    multiplier: 0.3065,
    exponent: 1.1375,
  },
  5: {
    multiplier: 0.2036,
    exponent: 1.2053,
  },
  6: {
    multiplier: 0.2821,
    exponent: 1.072,
  },
  7: {
    multiplier: 0.3488,
    exponent: 1.0765,
  },
}

const deductValueMap = {
  raveling: {
    low: { multiplier: 0, exponent: 0 },
    medium: { multiplier: 9.517, exponent: 0.3145 },
    high: { multiplier: 16.462, exponent: 0.364 },
    dvmax: 100,
  },
  bleeding: {
    low: { multiplier: 2, exponent: 0.4784 },
    medium: { multiplier: 3.2495, exponent: 0.55 },
    high: { multiplier: 5.5855, exponent: 0.5795 },
    dvmax: 100,
  },
  patching: {
    low: { multiplier: 1.8181, exponent: 0.8715 },
    medium: { multiplier: 8.1811, exponent: 0.5446 },
    high: { multiplier: 18.902, exponent: 0.4013 },
    dvmax: 50,
  },
  corrugation: {
    low: { multiplier: 1.3868, exponent: 0.9126 },
    medium: { multiplier: 14.25, exponent: 0.449 },
    high: { multiplier: 28.634, exponent: 0.3054 },
    dvmax: 100,
  },
  depression: {
    low: { multiplier: 7.6962, exponent: 0.4001 },
    medium: { multiplier: 13.128, exponent: 0.3628 },
    high: { multiplier: 19.841, exponent: 0.3237 },
    dvmax: 100,
  },
  rut: {
    low: { multiplier: 6.2214, exponent: 0.6388 },
    medium: { multiplier: 15.368, exponent: 0.4515 },
    high: { multiplier: 22.913, exponent: 0.4214 },
    dvmax: 100,
  },
  bump: {
    low: { multiplier: 1.1134, exponent: 1.1624 },
    medium: { multiplier: 4.4486, exponent: 0.9396 },
    high: { multiplier: 29.062, exponent: 0.392 },
    dvmax: 30,
  },
  sagging: {
    low: { multiplier: 1.1134, exponent: 1.1624 },
    medium: { multiplier: 4.4486, exponent: 0.9396 },
    high: { multiplier: 29.062, exponent: 0.392 },
    dvmax: 30,
  },
  potholes: {
    low: { multiplier: 17.502, exponent: 0.4969 },
    medium: { multiplier: 26.46, exponent: 0.5092 },
    high: { multiplier: 50.394, exponent: 0.3869 },
    dvmax: 100,
  },
  alligatorCrack: {
    low: { multiplier: 11.221, exponent: 0.379 },
    medium: { multiplier: 18.383, exponent: 0.3307 },
    high: { multiplier: 25.944, exponent: 0.356 },
    dvmax: 100,
  },
  blockCrack: {
    low: { multiplier: 2.6391, exponent: 0.5043 },
    medium: { multiplier: 3.3913, exponent: 0.5867 },
    high: { multiplier: 6.3245, exponent: 0.6282 },
    dvmax: 100,
  },
  edgeCrack: {
    low: { multiplier: 1.0348, exponent: 0.7132 },
    medium: { multiplier: 5.7813, exponent: 0.3913 },
    high: { multiplier: 11.747, exponent: 0.3082 },
    dvmax: 60,
  },
}

const quantityTypes = new Set([defectTypes.POTHOLES])

const complexTypes = new Set([
  defectTypes.LONGITUDINAL_CRACK,
  defectTypes.TRANSVERSE_CRACK,
  // defectTypes.THERMAL_CRACK
])

const divideBy5 = new Set([
  defectTypes.LOOSE_GRAVEL,
  defectTypes.DRAINAGE_ISSUE,
])

const divideBy4 = new Set([
  defectTypes.DUSTY_SURFACE,
  defectTypes.CORRUGATION,
  defectTypes.POTHOLES,
  defectTypes.DITCH,
  defectTypes.SIDE_SLOPE,
  defectTypes.HOMOGENEOUS_MATERIALS,
  defectTypes.SHARP_ELEVATION,
  defectTypes.WIDTH_VARIATION,
  defectTypes.RUT,

  // Not specified in document, assumed to be /4
  defectTypes.CROWN,
  defectTypes.UNEVEN_GRAVEL,
])

const divideBy3 = new Set([defectTypes.WASHING_OUT])

const minDeductValue = 0.05

function complexDeductValue(density) {
  const multiplier1 = -0.000001
  const exponent1 = 4

  const multiplier2 = 0.0003
  const exponent2 = 3

  const multiplier3 = 0.0293
  const exponent3 = 2

  const multiplier4 = 1.2123

  const subtractor = 0.8777

  return (
    multiplier1 * density ** exponent1 +
    multiplier2 * density ** exponent2 -
    multiplier3 * density ** exponent3 +
    multiplier4 * density -
    subtractor
  )
}

function applyCorrectionFactor(defects, highestDeductValue) {
  defects.sort((a, b) => b.deductValue - a.deductValue)

  let m = 1 + (9 / 98) * (100 - highestDeductValue)
  m = clamp(round(m, 1), 0, 10)

  let correctionFactor = round(m - Math.floor(m), 1)

  for (let i = 7; i < defects.length; i++) {
    defects[i].deductValue = round(defects[i].deductValue * correctionFactor, 1)
  }

  let maxCorrectedDeductValue = 0

  for (let i = defects.length - 1; i >= 0; i--) {
    const totalDeductValue = sumBy(defects, 'deductValue')
    const equationIndex = clamp(i + 1, 1, 7)
    const { multiplier, exponent } =
      correctedDeductValueEquations[equationIndex]
    const correctedDeductValue = multiplier * totalDeductValue ** exponent

    if (correctedDeductValue > maxCorrectedDeductValue) {
      maxCorrectedDeductValue = correctedDeductValue
    }

    defects[i].deductValue = 2
  }

  return 100 - maxCorrectedDeductValue
}

function calculateDensity(defect, sampleArea) {
  const type = defect.type
  const widthStart = defect.roadSideDistance[0]
  const widthEnd = defect.roadSideDistance[1]
  const defectWidth = Math.abs(widthEnd - widthStart) || 0.1

  const lengthStart = defect.vehicleDistance[0]
  const lengthEnd = defect.vehicleDistance[1]
  const defectLength = Math.abs(lengthEnd - lengthStart) || 0.1

  const defectArea = defectWidth * defectLength

  let density

  if (quantityTypes.has(type)) {
    const defectCount = defect.quantity
    density = (defectCount / sampleArea) * 100
  } else {
    density = (defectArea / sampleArea) * 100
  }

  return density
}

export function calculatePavedPCI(defectsGroupedByStation, segment) {
  const result = []

  for (const defects of Object.values(defectsGroupedByStation)) {
    const stationResult = []

    if (defects) {
      let hasSurfaceChange = false
      let highestDeductValue = 0

      for (const defect of defects) {
        if (defect.type === defectTypes.SURFACE_CHANGE) {
          hasSurfaceChange = true
        }
        const roadWidth =
          defect.roadWidth || segment.roadWidth || defaultWidths.road
        const segmentLength = defaultLengths.segment
        const sampleArea = roadWidth * segmentLength

        const density = calculateDensity(defect, sampleArea)

        const { type, severity } = defect
        const severityText = severityMap[severity]
        let deductValue

        if (type === defectTypes.SURFACE_CHANGE) {
          deductValue = 100
        } else if (complexTypes.has(type)) {
          deductValue = complexDeductValue(density)
        } else {
          const { multiplier, exponent } = deductValueMap[type][severityText]
          deductValue = multiplier * density ** exponent
        }

        let maxDeductValue = deductValueMap[type]?.dvmax || 100
        let clampedDeductValue = clamp(deductValue, 0, maxDeductValue)
        let score = 100 - clampedDeductValue

        if (clampedDeductValue > highestDeductValue) {
          highestDeductValue = clampedDeductValue
        }

        stationResult.push({
          type,
          density,
          quantity: defect.quantity || 0,
          severity,
          severityText,
          deductValue: clampedDeductValue,
          score,
        })
      }

      let pci

      if (stationResult.length <= 2) {
        const deductValueSum = sumBy(stationResult, 'deductValue')
        pci = 100 - deductValueSum
      } else {
        pci = applyCorrectionFactor(stationResult, highestDeductValue)
      }

      pci = round(clamp(pci, 0, 100), 4)

      if (hasSurfaceChange) {
        pci = 0
      }

      stationResult.push(pci)
    }

    if (stationResult.length) {
      result.push(stationResult)
    } else {
      result.push([100])
    }
  }

  return result
}

export function calculateGravelPCI(defectsGroupedByStation, segment) {
  const result = []

  for (const defects of Object.values(defectsGroupedByStation)) {
    const stationResult = []

    if (defects) {
      let hasSurfaceChange = false
      let deductValueSum = 0

      for (const defect of defects) {
        if (defect.type === defectTypes.SURFACE_CHANGE) {
          hasSurfaceChange = true
        }
        const roadWidth =
          defect.roadWidth || segment.roadWidth || defaultWidths.road
        const segmentLength = defaultLengths.segment
        const sampleArea = roadWidth * segmentLength

        const { type, severity } = defect
        const severityText = severityMap[severity]

        const density = calculateDensity(defect, sampleArea)

        let divider

        if (divideBy5.has(type)) {
          divider = 5
        } else if (divideBy4.has(type)) {
          divider = 4
        } else if (divideBy3.has(type)) {
          divider = 3
        }

        const deductValue = hasSurfaceChange
          ? 0
          : 1 - ((severity - 1) / (divider - 1) + minDeductValue)
        const clampedDeductValue = clamp(deductValue, 0, 1)

        deductValueSum = deductValueSum + clampedDeductValue

        const body = {
          type,
          density,
          quantity: defect.quantity || 0,
          severity,
          severityText,
          deductValue,
        }

        stationResult.push(body)
      }

      const PCIg = (deductValueSum / defects.length) * 100
      let clampedPCIg = round(clamp(PCIg, 0, 100), 4)

      if (hasSurfaceChange) {
        clampedPCIg = 100
      }

      stationResult.push(clampedPCIg)
    }

    if (stationResult.length) {
      result.push(stationResult)
    } else {
      result.push([100])
    }
  }

  return result
}
