<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>$fas fa-download</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(granularity, index) in granularities"
        :key="index"
      >
        <v-btn
          text
          @click="downloadCSVs(granularity.value)"
        >
          {{ granularity.text }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'

import { defaultWidths } from '@/utils/constants'
import { assetTypes, IRIGranularities } from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import inventoryMixin from '@/mixins/inventoryDownloadMixin'

export default {
  name: 'AssetsDownload',

  mixins: [inventoryMixin],

  props: {
    segment: {
      type: Object,
      required: true,
    },

    orderedImages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      maxAssetsCount: 0,
      currentAssetGroup: null,
      currentAssetTypes: [],
      groups: {
        guardRails: [assetTypes.GUARD_RAILS],
        lampPosts: [assetTypes.LAMP_POSTS],
        roadMarkings: [assetTypes.ROAD_MARKINGS],
        signs: [
          assetTypes.DANGER_SIGNS,
          assetTypes.INDICATION_SIGNS,
          assetTypes.PRESCRIPTION_SIGNS,
        ],
      },
      headers: [
        {
          text: this.$t('enums.headers.roadName'),
          value: 'name',
        },
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
        },
        {
          text: this.$t('enums.headers.lat'),
          value: 'endLatitude',
        },
        {
          text: this.$t('enums.headers.lon'),
          value: 'endLongitude',
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
        },
        {
          text: this.$t('enums.headers.roadWidth'),
          value: 'roadWidth',
        },
        {
          text: this.$t('enums.headers.shoulderWidth'),
          value: 'shoulderWidth',
        },
        {
          text: this.$t('enums.headers.bikeLaneWidth'),
          value: 'bikeLaneWidth',
        },
      ],
    }
  },

  computed: {
    ...mapState('inventory', ['assets', 'groupToAssetsMap']),

    granularities() {
      return toSelectItems(IRIGranularities, 'enums.IRIGranularities')
    },
  },

  methods: {
    downloadCSVs(granularity) {
      this.granularity = granularity
      this.imagesGroupedByStation = this.groupImagesByEndStation(
        this.orderedImages,
        granularity,
      )

      for (const [group, assetTypes] of Object.entries(this.groups)) {
        this.currentAssetGroup = group
        this.currentAssetTypes = assetTypes
        const csvContent = this.generateCSV()
        this.triggerDownload(
          csvContent,
          `${this.segment.name}_${granularity}m_${group}.csv`,
        )
      }
    },

    generateCSVBody() {
      const assetsGroupedByStation = this.groupAssetsByEndStation(
        this.imagesGroupedByStation,
      )

      return this.generateBody(assetsGroupedByStation)
    },

    generateCSVHeaders() {
      const headers = [...this.headers]

      for (let i = 0; i < this.maxAssetsCount; i++) {
        const index = i + 1

        for (const assetHeader of this.assetHeaders()) {
          const text = `${this.$t(`views.inventory.${assetHeader}`)} ${index}`
          const value = assetHeader + index

          headers.push({
            text,
            value,
          })
        }
      }

      return headers
    },

    groupAssetsByEndStation(groupedImages) {
      const assetsGroupedByStation = {}

      for (const [endStationInt, images] of Object.entries(groupedImages)) {
        assetsGroupedByStation[endStationInt] = []

        for (const image of images) {
          const imageAssets = this.assets[image._id]?.assets

          if (!imageAssets) {
            continue
          }

          for (const [assetType, assetBody] of Object.entries(imageAssets)) {
            const hasAssetInCurrentGroup = this.currentAssetTypes.some(
              (type) => {
                return this.groupToAssetsMap[type]?.has(assetType)
              },
            )
            if (hasAssetInCurrentGroup) {
              assetsGroupedByStation[endStationInt].push({
                [assetType]: { ...assetBody, type: assetType },
              })
            }
          }
        }

        this.maxAssetsCount = Math.max(
          this.maxAssetsCount,
          assetsGroupedByStation[endStationInt].length,
        )
      }

      return assetsGroupedByStation
    },

    generateBody(assetsGroupedByStation) {
      const segmentName = this.segment.name
      const csvBody = []

      let sections

      if (this.granularity === IRIGranularities.TEN_METERS) {
        sections = this.segment.iriSections10m
      } else {
        sections = this.segment.iriSections
      }

      for (let i = 0; i < sections.length; i++) {
        const section = sections[i]

        const { startStation, endStation, endStationInt, endLocation, iri } =
          section

        const images = this.imagesGroupedByStation[endStationInt]
        const assets = assetsGroupedByStation[endStationInt]

        const { roadWidth, shoulderWidth, bikeLaneWidth } =
          this.calculateWidths(images)

        const body = {
          name: segmentName,
          startStation,
          endStation,
          endStationInt,
          endLatitude: endLocation.coordinates[1],
          endLongitude: endLocation.coordinates[0],
          iri,
          roadWidth,
          shoulderWidth,
          bikeLaneWidth,
        }

        this.appendAssetsToBody(body, assets)

        csvBody.push(body)
      }

      return csvBody
    },

    calculateWidths(images) {
      const roadWidths = []
      const shoulderWidths = []
      const bikeLaneWidths = []
      let addedSegementWidths = false

      for (const image of images) {
        const { roadWidth, shoulderWidth, bikeLaneWidth } = image

        if (roadWidth) {
          roadWidths.push(roadWidth)
          shoulderWidths.push(shoulderWidth)
          bikeLaneWidths.push(bikeLaneWidth)
        } else if (addedSegementWidths === false) {
          if (this.segment.roadWidth !== undefined) {
            roadWidths.push(this.segment.roadWidth)
            shoulderWidths.push(this.segment.shoulderWidth)
            bikeLaneWidths.push(this.segment.bikeLaneWidth)
          } else {
            roadWidths.push(defaultWidths.road)
            shoulderWidths.push(defaultWidths.shoulder)
            bikeLaneWidths.push(defaultWidths.bikeLane)
          }
          addedSegementWidths = true
        }
      }

      if (roadWidths.length === 0) {
        return {
          roadWidth: `${this.segment.roadWidth}m`,
          shoulderWidth: `${this.segment.shoulderWidth}m`,
          bikeLaneWidth: `${this.segment.bikeLaneWidth}m`,
        }
      }

      const minRoadWidth = Math.min(...roadWidths)
      const minShoulderWidth = Math.min(...shoulderWidths)
      const minBikeLaneWidth = Math.min(...bikeLaneWidths)
      const maxRoadWidth = Math.max(...roadWidths)
      const maxShoulderWidth = Math.max(...shoulderWidths)
      const maxBikeLaneWidth = Math.max(...bikeLaneWidths)

      const roadWidth =
        minRoadWidth === maxRoadWidth
          ? `${minRoadWidth}m`
          : `${minRoadWidth} - ${maxRoadWidth}m`
      const shoulderWidth =
        minShoulderWidth === maxShoulderWidth
          ? `${minShoulderWidth}m`
          : `${minShoulderWidth} - ${maxShoulderWidth}m`
      const bikeLaneWidth =
        minBikeLaneWidth === maxBikeLaneWidth
          ? `${minBikeLaneWidth}m`
          : `${minBikeLaneWidth} - ${maxBikeLaneWidth}m`

      return {
        roadWidth,
        shoulderWidth,
        bikeLaneWidth,
      }
    },

    appendAssetsToBody(body, assets) {
      for (let i = 0; i < assets.length; i++) {
        const index = i + 1
        const current = assets[i]
        const asset = Object.values(current)[0]

        for (const property of this.assetHeaders()) {
          const key = property + index
          let value

          if (property === 'condition') {
            value = this.$t(`enums.roadAssetConditions.${asset[property]}`)
          } else if (property === 'isOffRoad') {
            value = asset[property]
              ? this.$t('views.inventory.offRoad')
              : this.$t('views.inventory.onRoad')
          } else if (property === 'roadSide') {
            value = this.$t(`enums.side.${asset[property]}`)
          } else if (property === 'signSupport') {
            value = this.$t(`enums.signSupportTypes.${asset[property]}`)
          } else {
            value = asset[property]
          }

          body[key] = value
        }
      }
    },

    assetHeaders() {
      const headers = [
        'type',
        'referenceNo',
        'condition',
        'roadSide',
        'isOffRoad',
      ]

      if (this.currentAssetGroup === 'signs') {
        headers.push('signSupport')
      }

      return headers
    },
  },
}
</script>
