<template>
  <v-menu offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        color="primary"
        icon
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>$fas fa-download</v-icon>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="(granularity, index) in granularities"
        :key="index"
      >
        <v-btn
          text
          @click="downloadCSV(granularity.value)"
        >
          {{ granularity.text }}
        </v-btn>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex'
import { round, defaults } from 'lodash'

import { calculatePavedPCI, calculateGravelPCI } from '@/utils/pci'
import { roadCategories, IRIGranularities } from '@/utils/enum'
import { toSelectItems } from '@/utils/i18n'
import inventoryMixin from '@/mixins/inventoryDownloadMixin'

export default {
  name: 'DefectsDownload',

  mixins: [inventoryMixin],

  props: {
    segment: {
      type: Object,
      required: true,
    },

    orderedImages: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      maxDefectsCount10m: 0,
      maxDefectsCount100m: 0,
      missingStations: new Set(),

      defectHeaders: ['type', 'severity', 'density'],

      headers: [
        {
          text: this.$t('enums.headers.roadName'),
          value: 'name',
        },
        {
          text: this.$t('enums.headers.sectionStart'),
          value: 'startStation',
        },
        {
          text: this.$t('enums.headers.sectionEnd'),
          value: 'endStation',
        },
        {
          text: this.$t('enums.headers.lat'),
          value: 'endLatitude',
        },
        {
          text: this.$t('enums.headers.lon'),
          value: 'endLongitude',
        },
        {
          text: this.$t('enums.headers.IRI'),
          value: 'iri',
        },
        {
          text: this.$t('enums.headers.rut'),
          value: 'rut',
        },
        {
          text: this.$t('enums.headers.macrotexture'),
          value: 'macrotexture',
        },
        {
          text: this.$t('enums.headers.pci'),
          value: 'pci',
        },
      ],
    }
  },

  computed: {
    ...mapState('inventory', ['defects']),

    isGravelRoad() {
      return this.segment.roadCategory === roadCategories.gravel
    },

    granularities() {
      return toSelectItems(IRIGranularities, 'enums.IRIGranularities')
    },
  },

  methods: {
    downloadCSV(granularity) {
      this.granularity = granularity
      this.imagesGroupedByStation = this.groupImagesByEndStation(
        this.orderedImages,
        IRIGranularities.TEN_METERS,
      )

      for (const [key, value] of Object.entries(this.imagesGroupedByStation)) {
        if (value.length === 0) {
          this.missingStations.add(parseInt(key))
        }
      }

      const csvContent = this.generateCSV()
      this.triggerDownload(
        csvContent,
        `${this.segment.name}_${granularity}m.csv`,
      )
    },

    generateCSVBody() {
      const defectsGroupedByStation = this.groupDefectsByStation(
        this.imagesGroupedByStation,
      )

      return this.generateBody(defectsGroupedByStation)
    },

    groupDefectsByStation(groupedImages) {
      const defectsGroupedByStation = {}

      for (const [endStationInt, images] of Object.entries(groupedImages)) {
        const stationDefects = []

        for (const image of images) {
          const imageDefects = this.defects[image._id]?.defects

          if (!imageDefects) {
            continue
          }

          for (const [defectType, defectBody] of Object.entries(imageDefects)) {
            stationDefects.push({
              ...defectBody,
              type: defectType,
              roadWidth: image?.roadWidth || null,
            })
          }
        }

        if (stationDefects.length > 0) {
          defectsGroupedByStation[endStationInt] = stationDefects
          this.maxDefectsCount10m = Math.max(
            this.maxDefectsCount10m,
            defectsGroupedByStation[endStationInt].length,
          )
        } else {
          defectsGroupedByStation[endStationInt] = undefined
        }
      }

      return defectsGroupedByStation
    },

    generateBody(defectsGroupedByStation) {
      const PCIs = this.isGravelRoad
        ? calculateGravelPCI(defectsGroupedByStation, this.segment)
        : calculatePavedPCI(defectsGroupedByStation, this.segment)

      const name = this.segment.name
      const csvBody100m = []
      const csvBody10m = []

      const sections100m = this.segment.iriSections
      const sections10m = this.segment.iriSections10m

      let index100m = 0
      let index10m = 0
      let previous = null

      for (; index100m < this.segment.iriSections.length; index100m++) {
        const current100mSection = sections100m[index100m]
        const current100mEndStation = current100mSection.endStationInt
        const defects100m = []
        const pcis100m = []

        let current10mSection = sections10m[index10m]
        let current10mEndStation = current10mSection.endStationInt

        while (current10mEndStation <= current100mEndStation) {
          const { startStation, endStation, endStationInt, endLocation, iri } =
            current10mSection

          const body = {
            name,
            startStation,
            endStation,
            endStationInt,
            endLatitude: endLocation.coordinates[1],
            endLongitude: endLocation.coordinates[0],
            iri,
          }

          if (this.missingStations.has(endStationInt)) {
            if (!previous) {
              body.pci = 100
            }
            defaults(body, previous)
          } else {
            const PCI = PCIs[index10m]

            if (PCI) {
              const pci = PCI.pop()
              const defects = PCI

              this.appendDefectsToBody(body, defects)

              body.pci = pci
              defects100m.push(...defects)
              pcis100m.push(pci)
            }
          }

          csvBody10m.push(body)
          previous = body

          index10m++
          current10mSection = sections10m[index10m]
          current10mEndStation = current10mSection?.endStationInt
        }

        const body = {
          name,
          startStation: current100mSection.startStation,
          endStation: current100mSection.endStation,
          endStationInt: current100mSection.endStationInt,
          endLatitude: current100mSection.endLocation.coordinates[1],
          endLongitude: current100mSection.endLocation.coordinates[0],
          iri: current100mSection.iri,
        }

        this.maxDefectsCount100m = Math.max(
          this.maxDefectsCount100m,
          defects100m.length,
        )

        if (pcis100m.length === 0) {
          body.pci = null
          csvBody100m.push(body)
          this.appendDefectsToBody(body, defects100m)
          continue
        }

        const pcisSum = pcis100m.reduce((acc, pci) => acc + pci, 0)
        const pcisAvg = round(pcisSum / pcis100m.length, 4)

        body.pci = pcisAvg
        csvBody100m.push(body)
        this.appendDefectsToBody(body, defects100m)
      }

      return this.granularity === IRIGranularities.TEN_METERS
        ? csvBody10m
        : csvBody100m
    },

    generateCSVHeaders() {
      const headers = [...this.headers]

      const maxDefectsCount =
        this.granularity === IRIGranularities.TEN_METERS
          ? this.maxDefectsCount10m
          : this.maxDefectsCount100m

      for (let i = 0; i < maxDefectsCount; i++) {
        const index = i + 1

        for (const defectHeader of this.defectHeaders) {
          const text = `${this.$t(`views.inventory.${defectHeader}`)} ${index}`
          const value = defectHeader + index

          headers.push({
            text,
            value,
          })
        }
      }

      return headers
    },

    appendDefectsToBody(body, defects) {
      for (let i = 0; i < defects.length; i++) {
        const index = i + 1
        const defect = defects[i]

        for (const property of this.defectHeaders) {
          const key = property + index
          let value

          if (property === 'severity') {
            const { severity, severityText } = defect
            value = `${severity} (${this.$t(`enums.severity.${severityText}`)})`
          } else if (property === 'type') {
            value = this.$t(`enums.defects.${defect.type}`)
          } else {
            value = defect[property]
          }

          body[key] = value
        }
      }
    },
  },
}
</script>
